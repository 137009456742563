import React, { useState } from "react";
import axios from "axios";
import FQDropdownBox from "../../../components/FQDropdownBox/FQDropdownBox";
import CoordinatorsDropdown from "../../../components/CoordinatorsDropdown/CoordinatorsDropdown";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import {
  rebuildExperienceFQObject,
  rebuildTalentsFQObject,
  rebuildLicensesFQObject,
  rebuildAcademicDegreeFQObject,
  rebuildCertificateFQObject,
  rebuildLanguagesFQObject,
  rebuildDynamicsFQObject,
  rebuildSalaryLocationFQObject,
} from "./FilterQuestions/RebuildFilterQuestions/RebuildFilterQuestions";
import { grey } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Switch,
  Button,
  Icon,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  Box,
  Checkbox,
  Divider,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AxiosErrorHandling from "../../../components/AxiosErrorHandling/AxiosErrorHandling";
import ConfigurationListsContext from "../../../context/configurationListsContext";
import { CitiesList } from "../../../config/jobsConfigData";
import CustomIcon from "../../../components/NumberCircleIcon/NumberCircleIcon";
import { withRouter } from "react-router-dom";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_DYNAMICS,
  AI_LANGUAGES,
  AI_LICENSES,
  AI_LOCATION,
  AI_SALARY_EXPECTATIONS,
} from "../../../config/constants";
import { getSalaryQuestion } from "../../../config/jobsConfigData";
import Spinner from "../../../UI/Spinner/Spinner";
import FilterQuestionDisplayPrevew from "./FilterQuestions/FilterQuestionDisplayPreview";
import FilterQuestionRelationsTag from "./FilterQuestions/FilterQuestionRelationsTag";
import { useContext } from "react";
import { AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiFormControl-fullWidth": {
      marginTop: theme.spacing(2),
    },
    "& .MuiButton-endIcon": {
      display: "inherit",
      marginLeft: 8,
      marginRight: -4,
    },
  },
  gridSpace: {
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(2),
    },
  },
  collapse: {
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      alignItems: "center",
    },
  },
  textField: {
    "& .MuiSvgIcon-root": {
      color: grey[500],
    },
  },
  addJobProgressMenu: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
  ButtonRight: {
    justifyContent: "flex-start",
  },
}));

const targetMenuItems = () => {
  let tmi = [];
  for (let i = 0; i < 30; i++) {
    tmi = [
      ...tmi,
      <MenuItem key={(i + 1).toString()} value={i + 1}>
        {(i + 1).toString()}
      </MenuItem>,
    ];
  }
  return tmi;
};

const AddNewJob = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [wizardStep, setWizardStep] = React.useState(1);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [ownerId, setOwnerId] = React.useState(0);
  const [selectedJobExtent, setSelectedJobExtent] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [selectedArea, setSelectedArea] = React.useState([]);
  const [selectedProfessions, setSelectedProfessions] = React.useState([]);
  const [salaryMinimum, setSalaryMinimum] = React.useState(0);
  const [salaryMaximum, setSalaryMaximum] = React.useState(2000);
  const [discreteSalary, setDiscreteSalary] = React.useState(true);
  const [discreteCompany, setDiscreteCompany] = React.useState(true);
  const [jobReqs, setJobsReqs] = React.useState("");
  const [jobDescription, setJobDescription] = React.useState("");
  const [openSalaryQuestion, setOpenSalaryQuestion] = React.useState(false);
  const [openSalaryValueBy, setOpenSalaryValueBy] = React.useState("month");
  const [jobCity, setJobCity] = React.useState("");
  const [jobRecruitmentTarget, setJobRecruitmentTarget] = React.useState(1);
  const [jobId, setJobId] = React.useState(-1); //always -1 in add mode, or job id in edit mode
  const [jobExternalId, setJobExternalId] = React.useState("");
  const [coordinatorName, setCoordinatorName] = React.useState("");
  const [employmentType, setEmploymentType] = React.useState("recruitment"); //the other option is employment
  const [isEditMode, setIsEditMode] = React.useState(false); //if this is true, we are in job edit mode
  const [isPublished, setIsPublished] = React.useState(false); //true publish date is not null
  const [autoDistribution, setAutoDistribution] = React.useState(false); //If true, after import users will be published.
  const [
    exploreSearchSavedSuccessfully,
    setExploreSearchSavedSuccessfully,
  ] = useState(false);
  const [locationData, setLocationData] = useState({
    additionalInfo: "",
    tags: [],
  });
  const [salaryData, setSalaryData] = useState({
    additionalInfo: "",
    tags: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = React.useState(null);
  const [
    showDuplicateExternalIDWarning,
    setShowDuplicateExternIDWarning,
  ] = useState(false);
  const [dulicateExternalIDList, setDuplicateExternalIdList] = useState([]);
  const [
    experienceFilterQuestions,
    setExperienceFilterQuestions,
  ] = React.useState([]);
  const [talentsFilterQuestions, setTalentsFilterQuestions] = React.useState(
    []
  );
  const [licensesFilterQuestions, setLicensesFilterQuestions] = React.useState(
    []
  );
  const [
    languagesFilterQuestions,
    setLanguagesFilterQuestions,
  ] = React.useState([]);
  const [dynamicFilterQuestions, setDynamicsFilterQuestions] = React.useState(
    []
  );
  const [
    academicDegreeFilterQuestions,
    setAcademicDegreeFilterQuestions,
  ] = React.useState([]);
  const [
    certificateFilterQuestions,
    setCertificateFilterQuestions,
  ] = React.useState([]);
  const professionsList = useContext(ConfigurationListsContext).professionsList;
  const handleMinimumSalaryChange = (event) => {
    setSalaryMinimum(parseInt(event.target.value));
    parseInt(event.target.value) > 250
      ? setOpenSalaryValueBy("month")
      : setOpenSalaryValueBy("hour");
  };
  const handleMaximumSalaryChange = (event) => {
    setSalaryMaximum(parseInt(event.target.value));
  };
  const handleJobsReqsChange = (event) => {
    setJobsReqs(event.target.value);
  };
  const handleJobsDescriptionChange = (event) => {
    setJobDescription(event.target.value);
  };

  const handleOpenSalaryValueChange = (event) => {
    setOpenSalaryValueBy(event.target.value);
  };

  const postNewJob = async () => {
    const jobData = {
      title: jobTitle,
      "jobs-sectors": selectedProfessions.map((prof) => prof.id).join(", "),
      author: ownerId ? ownerId : localStorage.getItem("userId"),
      fields: {
        job_extent: selectedJobExtent.id,
        company_id: selectedCompany.id,
        job_area: selectedArea.value,
        minimum_salary: salaryMinimum,
        maximum_salary: salaryMaximum,
        discrete_company: discreteCompany,
        discrete_salary: discreteSalary,
        job_city: jobCity,
        external_id: jobExternalId,
        employment_type: employmentType,
        job_requirements: jobReqs,
        job_recruitment_target: jobRecruitmentTarget,
        open_salary_question: openSalaryQuestion,
        open_salary_value_by: openSalaryValueBy,
        coordinator_name: coordinatorName,
        job_time_limit: 72,
        job_status: "active",
        auto_distribution: autoDistribution,
      },
      content: jobDescription,
      status: "publish",
    };
    //special case where I need to add Authrorization because this is being used by candidates as well
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    let newJobId = -1;
    axios
      .post("/wp-json/wp/v2/jobs", jobData)
      .then((jobRes) => {
        newJobId = parseInt(jobRes.data.id);
        axios.post("/wp-json/api/v2/updateJobFilterQuestions", {
          jobId: parseInt(jobRes.data.id),
          experience: { experienceFilterQuestions },
          talents: { talentsFilterQuestions },
          licenses: { licensesFilterQuestions },
          academicDegree: { academicDegreeFilterQuestions },
          certificate: { certificateFilterQuestions },
          languages: { languagesFilterQuestions },
          dynamics: { dynamicFilterQuestions },
          salary: { salaryData },
          location: { locationData },
        });
      })
      .then((fqRes) => {
        props.addedSuccessfully(newJobId);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const updateJob = async () => {
    const jobData = {
      title: jobTitle,
      author: ownerId ? ownerId : localStorage.getItem("userId"),
      "jobs-sectors": selectedProfessions.map((prof) => prof.id).join(", "),
      fields: {
        job_extent: selectedJobExtent.id,
        company_id: selectedCompany.id,
        job_area: selectedArea.value,
        minimum_salary: salaryMinimum,
        maximum_salary: salaryMaximum,
        discrete_company: discreteCompany,
        discrete_salary: discreteSalary,
        employment_type: employmentType,
        job_city: jobCity,
        external_id: jobExternalId,
        job_recruitment_target: jobRecruitmentTarget,
        open_salary_question: openSalaryQuestion,
        open_salary_value_by: openSalaryValueBy,
        coordinator_name:
          coordinatorName === ""
            ? localStorage.getItem("userDisplayName")
            : coordinatorName,
        job_time_limit: 72,
        job_requirements: jobReqs,
        job_status: "active",
        auto_distribution: autoDistribution,
      },
      content: jobDescription,
      status: "publish",
    };

    axios
      .post(`/wp-json/wp/v2/jobs/${jobId}`, jobData)
      .then((jobRes) => {
        return axios.post("/wp-json/api/v2/updateJobFilterQuestions", {
          jobId: parseInt(jobRes.data.id),
          experience: { experienceFilterQuestions },
          talents: { talentsFilterQuestions },
          licenses: { licensesFilterQuestions },
          academicDegree: { academicDegreeFilterQuestions },
          certificate: { certificateFilterQuestions },
          languages: { languagesFilterQuestions },
          dynamics: { dynamicFilterQuestions },
          salary: { salaryData },
          location: { locationData },
        });
      })
      .then((res) => {
        props.addedSuccessfully(jobId);
      })
      .catch((err) => {
        setError(err.response);
      });
  };

  const handleAddFQ = (fqValue, type) => {
    //get the current type, and detect what FQ to add
    switch (type) {
      case AI_EXPERIENCES:
        setExperienceFilterQuestions(fqValue);
        break;
      case AI_SKILLS:
        setTalentsFilterQuestions(fqValue);
        break;
      case AI_LICENSES:
        setLicensesFilterQuestions(fqValue);
        break;
      case AI_ACADEMIC_DGREE:
        setAcademicDegreeFilterQuestions(fqValue);
        break;
      case AI_CERTIFICATES:
        setCertificateFilterQuestions(fqValue);
        break;
      case AI_LANGUAGES:
        setLanguagesFilterQuestions(fqValue);
        break;
      case AI_DYNAMICS:
        setDynamicsFilterQuestions(fqValue);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (props.editMode > 0) {
      setIsEditMode(true);
      setJobId(props.editMode);
      setIsLoading(true);
      axios
        .get(`/wp-json/api/v2/job/${props.editMode}`)
        .then((jobRes) => {
          setJobId(jobRes.data.id);
          setOwnerId(parseInt(jobRes.data.author));
          setJobTitle(jobRes.data.title);
          setJobDescription(jobRes.data.description);
          setSelectedJobExtent(jobRes.data.job_extent);
          setSelectedCompany(jobRes.data.company);
          setSelectedArea(jobRes.data.job_area);
          setSalaryMinimum(parseInt(jobRes.data.minimum_salary));
          setSalaryMaximum(parseInt(jobRes.data.maximum_salary));
          setDiscreteCompany(jobRes.data.discrete_company);
          setDiscreteSalary(jobRes.data.discrete_salary);
          setJobExternalId(jobRes.data.external_id);
          setSelectedProfessions(jobRes.data.professions);
          setEmploymentType(jobRes.data.employment_type);
          setJobsReqs(jobRes.data.job_requirements);
          setJobCity(jobRes.data.city);
          setCoordinatorName(jobRes.data.coordinator_name);
          setAutoDistribution(jobRes.data.auto_distribution);
          setJobRecruitmentTarget(parseInt(jobRes.data.job_recruitment_target));
          setOpenSalaryQuestion(jobRes.data.open_salary_question);
          setIsPublished(
            jobRes.data.published &&
              jobRes.data.published !== null &&
              jobRes.data.published !== ""
              ? true
              : false
          );
          setOpenSalaryValueBy(
            jobRes.data.open_salary_question
              ? jobRes.data.open_salary_value_by
              : "month"
          );
          const rawFilterQuestions = jobRes.data.filter_questions;
          const rawExperienceFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "experience"
          );
          const rawAcademicFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "academicDegree"
          );
          const rawLicensesFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "license"
          );
          const rawTalentsFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "talents"
          );
          const rawCertificatesFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "certificate"
          );
          const rawLanguagesFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "languages"
          );
          const rawSalaryFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "salary"
          );
          const rawLocationFQ = rawFilterQuestions.filter(
            (fqItem) => fqItem.fq_type === "location"
          );
          const rawDynamicFQ = jobRes.data.dynamic_filter_questions;
          setDynamicsFilterQuestions(rebuildDynamicsFQObject(rawDynamicFQ));
          setExperienceFilterQuestions(
            rebuildExperienceFQObject(rawExperienceFQ)
          );
          setLicensesFilterQuestions(rebuildLicensesFQObject(rawLicensesFQ));
          setTalentsFilterQuestions(rebuildTalentsFQObject(rawTalentsFQ));
          setAcademicDegreeFilterQuestions(
            rawAcademicFQ && rawAcademicFQ.length
              ? rebuildAcademicDegreeFQObject(rawAcademicFQ)
              : []
          );
          setCertificateFilterQuestions(
            rawCertificatesFQ && rawCertificatesFQ.length
              ? rebuildCertificateFQObject(rawCertificatesFQ)
              : []
          );
          setLanguagesFilterQuestions(rebuildLanguagesFQObject(rawLanguagesFQ));
          setSalaryData(rebuildSalaryLocationFQObject(rawSalaryFQ));
          setLocationData(rebuildSalaryLocationFQObject(rawLocationFQ));
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err.response);
          setIsLoading(false);
        });
    }

    // in case of edit mode - get the data from the server:
  }, [props.editMode]);

  const verifyStepCompletion = (step) => {
    if (step === 1) {
      return (
        selectedCompany &&
        Object.keys(selectedCompany).length > 0 &&
        jobTitle.length > 2 &&
        jobDescription.length &&
        2 &&
        jobReqs.length > 2
      );
    }
    if (step === 2) {
      return (
        coordinatorName !== "" &&
        selectedProfessions.length > 0 &&
        selectedJobExtent &&
        Object.keys(selectedJobExtent).length > 0 &&
        selectedArea &&
        Object.keys(selectedArea).length > 0 &&
        jobCity !== ""
      );
    }
    return false;
  };

  const handleCollectJobSearchData = async () => {
    let dataToSave = [];
    //prepare the data to save:
    //Working Area:
    //MISSING: BuildInTags
    if (Object.keys(selectedArea).length > 0) {
      dataToSave = [
        ...dataToSave,
        {
          id: dataToSave.length + 1,
          value: {
            value: [selectedArea.id],
            type: "workingArea",
            isValid: true,
          },
        },
      ];
    }
    //professions:
    //Yaniv: as requested - profession is removed
    // if (selectedProfessions.length) {
    //   dataToSave = [
    //     ...dataToSave,
    //     {
    //       id: dataToSave.length + 1,
    //       value: {
    //         value: selectedProfessions.map((p) => p.id),
    //         type: "professions",
    //         isValid: true,
    //       },
    //     },
    //   ];
    // }
    //Salary Expectations:
    if (
      salaryMinimum > 0 &&
      salaryMaximum > 0 &&
      salaryMaximum > salaryMinimum
    ) {
      dataToSave = [
        ...dataToSave,
        {
          id: dataToSave.length + 1,
          value: {
            value: {
              value: {
                salaryFrom: salaryMinimum,
                salaryTo: salaryMaximum,
              },
            },
            tags: salaryData.tags,
            type: AI_SALARY_EXPECTATIONS,
          },
        },
      ];
    }

    //AI filter questions:
    if (Object.keys(experienceFilterQuestions).length) {
      experienceFilterQuestions.forEach((experienceFQ) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,
            value: {
              value: {
                type: professionsList.find(
                  (prof) =>
                    prof.id === parseInt(experienceFQ.selectedProfession)
                ).name,
                value: experienceFQ.minYears,
                tags: experienceFQ.tags,
              },
              type: AI_EXPERIENCES,
              isValid: true,
            },
          },
        ];
      });
    }
    if (Object.keys(talentsFilterQuestions).length) {
      talentsFilterQuestions.forEach((skillsFQ) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,

            value: {
              value: {
                //type: skillsFQ.skillName,
                type: "",
                value: skillsFQ.knowledgeLevelRequired,
                tags: skillsFQ.tags,
              },
              type: AI_SKILLS,
              isValid: true,
            },
          },
        ];
      });
    }
    if (Object.keys(licensesFilterQuestions).length) {
      licensesFilterQuestions.forEach((licenses) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,

            value: {
              value: {
                //type: licenses.licenseOn,
                type: "",
                tags: licenses.tags,
              },
              type: AI_LICENSES,
              isValid: true,
            },
          },
        ];
      });
    }
    if (Object.keys(certificateFilterQuestions).length) {
      certificateFilterQuestions.forEach((certificate) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,

            value: {
              value: {
                //type: certificate.selectedCertificate,
                type: "",
                tags: certificate.tags,
              },
              type: AI_CERTIFICATES,
              isValid: true,
            },
          },
        ];
      });
    }
    if (Object.keys(languagesFilterQuestions).length) {
      languagesFilterQuestions.forEach((lang) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,

            value: {
              value: {
                //type: lang.languageName.trim,
                type: "",
                value: lang.languageLevel,
                tags: lang.tags,
              },
              type: AI_LANGUAGES,
              isValid: true,
            },
          },
        ];
      });
    }
    if (Object.keys(academicDegreeFilterQuestions).length) {
      academicDegreeFilterQuestions.forEach((degree) => {
        dataToSave = [
          ...dataToSave,
          {
            id: dataToSave.length + 1,

            value: {
              value: [degree.selectedDegree],
              type: AI_ACADEMIC_DGREE,
              isValid: true,
            },
          },
        ];
      });
    }

    const save_search = {
      name: `שמירת חיתוך מתוך משרה ${jobId}, (מספר חיצוני: ${jobExternalId})`,
      isPublic: false,
      searchData: dataToSave,
    };
    //save the data:
    try {
      setIsLoading(true);
      const savedData = await axios.post(
        "/wp-json/api/v2/save_explore_search",
        save_search
      );
      if (savedData.data.length) setExploreSearchSavedSuccessfully(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setExploreSearchSavedSuccessfully(false);
    }
  };

  const handleCloseDuplicateExternalIDWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowDuplicateExternIDWarning(false);
  };

  const checkPreviousExternalID = async (id) => {
    // Check if ID is empty
    if (!id || id.trim() === "") {
      console.warn("ID is empty. Skipping API request.");
      return;
    }

    const externalIDexistsRequest = await axios.get(
      "/wp-json/api/v2/getOpenJobsByExternalID",
      {
        params: { externalID: id },
      }
    );

    // Check the response data
    const response = externalIDexistsRequest.data;

    if (Array.isArray(response) && response.length > 0) {
      setDuplicateExternalIdList(response);
      setShowDuplicateExternIDWarning(true);
    }
  };

  const defaultProps = {
    options: React.useContext(ConfigurationListsContext).customersList,
    getOptionLabel: (cust) => cust.name,
  };
  if (isLoading) return <Spinner open={true} />;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={200}
            my={"100px"}
            mx={"auto"}
            className={classes.addJobProgressMenu}
          >
            <Button
              color="primary"
              startIcon={<SaveRoundedIcon />}
              className={classes.ButtonRight}
              disabled
            >
              שמור כטיוטא
            </Button>
            <Button
              color="primary"
              startIcon={<BookmarkRoundedIcon />}
              className={classes.ButtonRight}
              onClick={handleCollectJobSearchData}
              disabled={!isEditMode}
            >
              הוסף למועדפים לחיתוך
            </Button>
            <Typography variant="h5" color="textPrimary">
              משרה חדשה
            </Typography>
            <CustomIcon
              step={"01"}
              label={"תיאור המשרה"}
              isActive={wizardStep === 1}
              stepClick={() => setWizardStep(1)}
              completed={verifyStepCompletion(1)}
            />
            <CustomIcon
              step={"02"}
              label={"פרטי המשרה"}
              isActive={wizardStep === 2}
              stepClick={() => setWizardStep(2)}
              completed={verifyStepCompletion(2)}
            />
            <CustomIcon
              step={"03"}
              label={"שאלות סינון"}
              isActive={wizardStep === 3}
              stepClick={() => setWizardStep(3)}
              completed={verifyStepCompletion(3)}
            />
          </Box>
        </Grid>
        <ConfigurationListsContext.Consumer>
          {(context) => (
            <Grid item xs={10} align="right">
              <Paper className={classes.paper}>
                <Grid container spacing={2} className={classes.gridSpace}>
                  <Grid item xs={1}></Grid>
                  {/* /***** THIS SECTION IS DEPEND ON WIZARD STEP */}
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      {wizardStep === 1 && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Box my={theme.spacing(4)}>
                              <Typography variant="h4" color="textPrimary">
                                {isEditMode ? "עדכון משרה" : "הוספת משרה חדשה"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Autocomplete
                              {...defaultProps}
                              id="disable-close-on-select"
                              style={{ flex: 1 }}
                              onChange={(e, value) => {
                                setSelectedCompany(value);
                              }}
                              value={
                                selectedCompany &&
                                Object.keys(selectedCompany).length
                                  ? context.customersList.filter(
                                      (item) => item.id === selectedCompany.id
                                    )[0]
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  value={selectedCompany}
                                  style={{ marginTop: 8 }}
                                  label="שם החברה"
                                  variant="filled"
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FormControlLabel
                              label="חברה דיסקרטית"
                              control={
                                <Switch
                                  checked={discreteCompany ? true : false} //could be 1
                                  color="primary"
                                  onChange={() =>
                                    setDiscreteCompany(!discreteCompany)
                                  }
                                  name="DiscreteCompany"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl component="fieldset">
                              <FormLabel>סוג העסקה</FormLabel>
                              <RadioGroup
                                aria-label="employmentType"
                                name="employmentType"
                                value={employmentType}
                                onChange={(event) =>
                                  setEmploymentType(event.target.value)
                                }
                                row
                              >
                                <FormControlLabel
                                  value="recruitment"
                                  control={<Radio color="primary" />}
                                  label="השמה"
                                />
                                <FormControlLabel
                                  value="employment"
                                  control={<Radio color="primary" />}
                                  label="העסקה"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              label="שם המשרה"
                              id="jobTitle"
                              margin="normal"
                              variant="filled"
                              fullWidth
                              value={jobTitle}
                              onChange={(e) => setJobTitle(e.target.value)}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="job-description"
                              label="תיאור המשרה"
                              InputLabelProps={{
                                shrink: jobDescription !== "",
                              }}
                              multiline
                              onChange={handleJobsDescriptionChange}
                              fullWidth
                              rows={5}
                              defaultValue={jobDescription}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="job-requirements"
                              label="דרישות המשרה"
                              multiline
                              fullWidth
                              onChange={handleJobsReqsChange}
                              rows={5}
                              InputLabelProps={{
                                shrink: jobReqs !== "",
                              }}
                              defaultValue={jobReqs}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              label="הפצה אוטומטית"
                              control={
                                <Switch
                                  checked={autoDistribution ? true : false}
                                  color="primary"
                                  onChange={() =>
                                    setAutoDistribution(!autoDistribution)
                                  }
                                  name="autDistribution"
                                />
                              }
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                      {wizardStep === 2 && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Box my={theme.spacing(4)}>
                              <Typography variant="h4" color="textPrimary">
                                פרטי המשרה
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <ConfigurationListsContext.Consumer>
                              {(context) => (
                                <CoordinatorsDropdown
                                  coordsList={context.users}
                                  selectedAuthor={
                                    ownerId > 0
                                      ? ownerId
                                      : parseInt(localStorage.getItem("userId"))
                                  }
                                  onSelect={(value) => setOwnerId(value.id)}
                                  label="משרה בבעלות"
                                />
                              )}
                            </ConfigurationListsContext.Consumer>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              required
                              label="שם רכזת לתצוגה"
                              id="displayName"
                              margin="none"
                              fullWidth
                              variant="filled"
                              value={coordinatorName}
                              onChange={(e) =>
                                setCoordinatorName(e.target.value)
                              }
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              limitTags={2}
                              disabled={isPublished}
                              options={
                                context.professionsList
                                  ? context.professionsList.sort(
                                      (a, b) => a.parent_id > b.parent_id
                                    )
                                  : null
                              }
                              getOptionLabel={(prof) => prof.name}
                              onChange={(e, value) =>
                                setSelectedProfessions(value)
                              }
                              value={selectedProfessions.map(
                                (sp) =>
                                  context.professionsList.filter(
                                    (item) => item.id === sp.id
                                  )[0]
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="בחר מקצועות רלוונטיים"
                                  placeholder="מקצועות"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              options={context.jobExtentList}
                              getOptionLabel={(je) => je.value}
                              disabled={isPublished}
                              id="jobExtent"
                              onChange={(e, value) =>
                                setSelectedJobExtent(value)
                              }
                              value={
                                selectedJobExtent !== null &&
                                Object.keys(selectedJobExtent).length
                                  ? Object.values(
                                      context.jobExtentList.filter(
                                        (item) =>
                                          item.id === selectedJobExtent.id
                                      )
                                    )[0]
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="none"
                                  value={selectedJobExtent}
                                  label="היקף המשרה"
                                  required
                                  variant="filled"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              required
                              label="מספר משרה חיצוני"
                              id="externalId"
                              margin="none"
                              variant="filled"
                              value={jobExternalId}
                              fullWidth
                              onChange={(e) => setJobExternalId(e.target.value)}
                              onBlur={(e) => {
                                //now check if this id was used before
                                checkPreviousExternalID(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth>
                              <InputLabel id="recruitmentTarget">
                                תקנים לגיוס
                              </InputLabel>

                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={jobRecruitmentTarget}
                                onChange={(event) =>
                                  setJobRecruitmentTarget(event.target.value)
                                }
                                label="תקנים"
                                labelWidth={200}
                              >
                                <MenuItem value={0}>
                                  <em>ללא יעד</em>
                                </MenuItem>
                                {targetMenuItems()}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Divider
                            style={{
                              width: "100%",
                              marginTop: theme.spacing(2),
                              marginBottom: theme.spacing(2),
                            }}
                          />
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              מיקום המשרה:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Autocomplete
                              options={context.areasList}
                              getOptionLabel={(area) => area.value}
                              disabled={isPublished}
                              id="area"
                              onChange={(e, value) => {
                                setSelectedArea(value);
                              }}
                              value={
                                selectedArea !== null &&
                                Object.keys(selectedArea).length
                                  ? context.areasList.find(
                                      (item) => item.id === selectedArea.id
                                    )
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  required
                                  value={
                                    selectedArea !== null
                                      ? Object.values(selectedArea)
                                      : null
                                  }
                                  label="אזור המשרה"
                                  variant="filled"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Autocomplete
                              freeSolo
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              disabled={isPublished}
                              id="tags-cities"
                              options={CitiesList.map((city) => city.name).sort(
                                (a, b) => a.name > b.name
                              )}
                              onChange={(e, value) => setJobCity(value)}
                              value={jobCity !== "" ? jobCity : ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="יישוב"
                                  placeholder="יישוב/עיר"
                                  margin="normal"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              label="פרטים נוספים"
                              id="locationAddInfo"
                              margin="normal"
                              fullWidth
                              variant="filled"
                              value={
                                locationData && locationData.additionalInfo
                                  ? locationData.additionalInfo
                                  : ""
                              }
                              onChange={(e) =>
                                setLocationData({
                                  ...locationData,
                                  additionalInfo: e.target.value,
                                })
                              }
                              className={classes.textField}
                              helperText="מידע נוסף להצגה בעת מילוי שאלת מיקום"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FilterQuestionRelationsTag
                              category={AI_LOCATION}
                              onSelect={(value) =>
                                setLocationData({
                                  ...locationData,
                                  tags: value,
                                })
                              }
                              value={locationData.tags}
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item xs={12}>
                            <FilterQuestionDisplayPrevew
                              previewQuestion={`האם עבודה בעיר <strong>${
                                jobCity ? jobCity : ""
                              }</strong> מתאימה לך?<br />${
                                locationData.additionalInfo
                                  ? locationData.additionalInfo
                                  : ""
                              }`}
                            />
                          </Grid>
                          <Divider
                            style={{
                              width: "100%",
                              marginTop: theme.spacing(2),
                              marginBottom: theme.spacing(2),
                            }}
                          />
                          <Grid item xs={12}>
                            <Typography variant="body1">שכר:</Typography>
                            <Box display="flex" alignItems="center">
                              <FormLabel
                                component="legend"
                                style={{ marginLeft: theme.spacing(2) }}
                              >
                                חשב את ערך השכר לפי:
                              </FormLabel>
                              <RadioGroup
                                aria-label="openSalaryBy"
                                name="openSalaryBy"
                                value={openSalaryValueBy}
                                onChange={handleOpenSalaryValueChange}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="month"
                                  control={<Radio color="primary" />}
                                  label="שכר חודשי"
                                  disabled={isPublished}
                                />
                                <FormControlLabel
                                  value="hour"
                                  control={<Radio color="primary" />}
                                  label="שכר שעתי"
                                  disabled={isPublished}
                                />
                              </RadioGroup>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                              error={salaryMinimum > salaryMaximum}
                              fullWidth
                              disabled={isPublished}
                            >
                              <InputLabel htmlFor="minimumAmount">
                                שכר החל מ
                              </InputLabel>
                              <OutlinedInput
                                id="minimumAmount"
                                value={salaryMinimum}
                                type="number"
                                inputProps={{ min: 0, step: 500 }}
                                onChange={handleMinimumSalaryChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    ₪
                                  </InputAdornment>
                                }
                                labelWidth={80}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                              fullWidth
                              disabled={isPublished}
                            >
                              <InputLabel htmlFor="maximumAmount">
                                שכר מקסימום
                              </InputLabel>
                              <OutlinedInput
                                id="maximumAmount"
                                value={salaryMaximum}
                                type="number"
                                inputProps={{ min: 0, step: 500 }}
                                onChange={handleMaximumSalaryChange}
                                startAdornment={
                                  <InputAdornment position="start">
                                    ₪
                                  </InputAdornment>
                                }
                                labelWidth={90}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              required
                              label="פרטים נוספים"
                              id="salaryAddInfo"
                              margin="none"
                              fullWidth
                              variant="filled"
                              value={
                                salaryData && salaryData.additionalInfo
                                  ? salaryData.additionalInfo
                                  : ""
                              }
                              onChange={(e) =>
                                setSalaryData({
                                  ...salaryData,
                                  additionalInfo: e.target.value,
                                })
                              }
                              helperText="מידע נוסף להצגה בעת מילוי שאלת שכר"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FilterQuestionRelationsTag
                              category={AI_SALARY_EXPECTATIONS}
                              onSelect={(value) =>
                                setSalaryData({
                                  ...salaryData,
                                  tags: value,
                                })
                              }
                              value={salaryData.tags}
                            />
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item xs={12}>
                            <FilterQuestionDisplayPrevew
                              previewQuestion={`${getSalaryQuestion(
                                salaryMinimum,
                                salaryMaximum,
                                openSalaryQuestion,
                                openSalaryValueBy
                              )}<br />${
                                salaryData.additionalInfo
                                  ? salaryData.additionalInfo
                                  : ""
                              }`}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormControlLabel
                              label="שכר דיסקרטי"
                              control={
                                <Switch
                                  checked={discreteSalary}
                                  color="primary"
                                  onChange={() =>
                                    setDiscreteSalary(!discreteSalary)
                                  }
                                  name="DiscreteJob"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={openSalaryQuestion}
                                  disabled={isPublished}
                                  color="primary"
                                  onChange={(e) =>
                                    setOpenSalaryQuestion(e.target.checked)
                                  }
                                  name="openSalaryQuestion"
                                />
                              }
                              label="הצג שאלת שכר פתוחה"
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                      {wizardStep === 3 && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Box my={theme.spacing(4)}>
                              <Typography
                                variant="h4"
                                color="textPrimary"
                                paragraph
                              >
                                הוספת שאלות סינון
                              </Typography>
                              <Typography variant="caption" color="textPrimary">
                                שאלות סינון אינן חובה. ניתן לדלג על שלב זה
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FQDropdownBox
                              fqName="ניסיון תעסוקתי"
                              fqValue={experienceFilterQuestions}
                              type={AI_EXPERIENCES}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="כישורים"
                              fqValue={talentsFilterQuestions}
                              type={AI_SKILLS}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="רישיונות"
                              fqValue={licensesFilterQuestions}
                              type={AI_LICENSES}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="שפות"
                              fqValue={languagesFilterQuestions}
                              type={AI_LANGUAGES}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="תארים אקדמיים"
                              fqValue={academicDegreeFilterQuestions}
                              type={AI_ACADEMIC_DGREE}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="תעודות"
                              fqValue={certificateFilterQuestions}
                              type={AI_CERTIFICATES}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                            <FQDropdownBox
                              fqName="שאלות דינאמיות"
                              fqValue={dynamicFilterQuestions}
                              textColor="error"
                              type={AI_DYNAMICS}
                              addFQ={handleAddFQ}
                              locked={isPublished}
                              isInEdit={isEditMode}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={theme.spacing(4)}
                    >
                      <div>
                        <Button
                          color="primary"
                          startIcon={<NavigateNextRoundedIcon />}
                          variant="outlined"
                          disabled={wizardStep === 1 ? true : false}
                          style={{
                            cursor: wizardStep === 1 ? "default" : "pointer",
                          }}
                          onClick={() =>
                            setWizardStep((prev) => (prev > 1 ? prev - 1 : 1))
                          }
                        >
                          אחורה
                        </Button>
                        <Button
                          variant="outlined"
                          className={classes.cancel}
                          onClick={props.cancelNewJob}
                        >
                          בטל
                        </Button>
                      </div>
                      {wizardStep === 3 ? (
                        <ErrorBoundary>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<Icon>add</Icon>}
                            disabled={
                              jobTitle === "" ||
                              salaryMinimum > salaryMaximum ||
                              coordinatorName === "" ||
                              Object.keys(selectedCompany).length === 0 ||
                              jobReqs === "" ||
                              jobDescription === ""
                            }
                            onClick={isEditMode ? updateJob : postNewJob}
                          >
                            {isEditMode ? "עדכן משרה" : "הוסף משרה"}
                          </Button>
                        </ErrorBoundary>
                      ) : (
                        <Button
                          color="primary"
                          endIcon={<NavigateBeforeRoundedIcon />}
                          variant="contained"
                          onClick={() =>
                            setWizardStep((prev) => (prev < 3 ? prev + 1 : 3))
                          }
                        >
                          המשך
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </ConfigurationListsContext.Consumer>
        <Snackbar
          open={exploreSearchSavedSuccessfully}
          autoHideDuration={6000}
          onClose={() => setExploreSearchSavedSuccessfully(false)}
        >
          <Alert severity="success">
            נתוני המשרה התווספו למועדפים לחיתוך באקספלור
          </Alert>
        </Snackbar>
      </Grid>
      {error &&
        error.response(
          <AxiosErrorHandling
            title="שגיאה בהוספת משרה"
            error={error.response}
          />
        )}
      {showDuplicateExternalIDWarning && (
        <Snackbar
          open={showDuplicateExternalIDWarning}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseDuplicateExternalIDWarning}
        >
          <Alert
            onClose={() => setShowDuplicateExternIDWarning(false)}
            severity="warning"
          >
            <AlertTitle>לידיעה!</AlertTitle>
            למספר זה כבר הופקה משרה אחת או יותר. המשרות הן:{" "}
            <ul>
              {dulicateExternalIDList.map((listItem) => (
                <a
                  href={`/jobs/dashboard/${listItem}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {listItem}
                </a>
              ))}
            </ul>
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};
export default withRouter(AddNewJob);
